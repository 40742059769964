import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Center } from '@chakra-ui/react';
import logo from '../assets/makeover-logo.png';
import backlogo from '../assets/back-logo.png';
import DetailContentFaq from '../components/DetailPage/DetailContentFaq';
import ErrorPage from '../components/ErrorPage/ErrorPage';

const DetailPage = ({ userId }) => {
    const { type, sub_type }= useParams();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/faq/${type}/${sub_type}`);
    };
    const [errorStatusCode, setErrorStatusCode] = useState(null);

    const handleErrorStatus = (statusCode) => {
        setErrorStatusCode(statusCode);
    };

    const renderItems = () => {
        return (
            <Center>
                <Box style={{ width: '600px' }}>
                    <Box display="flex" style={{ maxWidth: '600px', backgroundColor: 'black', marginBottom:'24px',height:'70px' }}>
                        <img src={logo} alt="Make Over Logo" style={{ maxWidth: '70px', marginLeft: '16px'}} />
                    </Box>
                    <Box style={{ maxWidth: '600px',marginBottom:'0px'}}>
                        <img src={backlogo}
                            alt="Back Logo"
                            onClick={handleClick}
                            style={{ maxWidth: '70px', marginLeft: '16px', cursor: 'pointer' }} />
                    </Box>
                </Box>
            </Center>

        );
    };

    if (errorStatusCode) {
        return <ErrorPage errorStatusCode={errorStatusCode} />;
    }

    return (
        <div>
            <Box>
                {renderItems()}
                <DetailContentFaq onErrorStatus={handleErrorStatus} userId={userId} />
            </Box>
        </div>
    );
};

export default DetailPage;
