import { useState, useEffect } from 'react';

const useGetDetailFaq = (type, sub_type, topic) => {
    const [detailFaq, setDetailFaq] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDetailFaq = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/affiliate/${process.env.REACT_APP_BRAND_NAME}/v1/faq/${topic}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                setDetailFaq(result);
            } catch (err) {
                const statusCode = err.message.split('status: ')[1];
                if (err.message.includes('HTTP error!')) {
                    console.log(`Error Status Code: ${statusCode}`);
                } else {
                    console.log(err.message);
                }
                setError(statusCode);
            }
        };
        fetchDetailFaq();
    }, [topic, type, sub_type]);

    return { detailFaq, error };
};

export default useGetDetailFaq;
