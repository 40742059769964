import React from 'react';
import { Grid, Text, Box } from '@chakra-ui/react';
import tipsBg from '../../assets/tipsBg.png';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import GlobalStyles from '../Helper/GlobalStyles';
import '@dannymichel/proxima-nova';

const RootBox = (props) => (
    <Box
        display="flex"
        overflowX="auto"
        p="24px 0px 16px 0px"
        borderRadius="8px"
        whiteSpace="nowrap"
        fontFamily="Bebas Neue, sans-serif"
        {...props}
    />
);

const CardBox = (props) => (
    <Box
        display="inline-block"
        width="200px"
        mr="16px"
        flexShrink="0"
        fontFamily="Bebas Neue, sans-serif"
        {...props}
    />
);

const WhitePaper = (props) => (
    <Box
        bg="#fff"
        borderRadius="5px"
        p="16px"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="normal"
        minHeight="120px"
        fontFamily="Bebas Neue, sans-serif"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        {...props}
    />
);

const OuterBox = (props) => (
    <Box
        width="100%"
        bg="#000"
        pt="16px"
        pb="0"
        overflowX="hidden"
        backgroundImage={`url(${tipsBg})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        fontFamily="Bebas Neue, sans-serif"
        {...props}
    />
);

const StyledLink = (props) => (
    <ChakraLink
        as={RouterLink}
        color="#000"
        fontWeight="600"
        fontSize="12px"
        letterSpacing="1.2px"
        textDecoration="none"
        position="relative"
        fontFamily="Proxima Nova"
        alignSelf="flex-start"
        _after={{
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(to right, #FF42A1 0%, #FF42A1 100%)',
            bottom: 0,
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease',
        }}
        _hover={{
            _after: {
                transform: 'scaleX(1.05)',
            }
        }}
        {...props}
    />
);

const StyledLinkAnchored = (props) => (
    <ChakraLink
        as={RouterLink}
        color="white"
        width="171px"
        textDecoration="none"
        position="relative"
        fontFamily="Proxima Nova"
        fontWeight="400"
        letterSpacing="1.2px"
        fontSize="12px"
        _after={{
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '1px',
            background: 'linear-gradient(to right, #FFF 0%, #FFF 100%)',
            bottom: 0,
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease',
        }}
        _hover={{
            _after: {
                transform: 'scaleX(1.05)',
            }
        }}
        {...props}
    />
);

const TipsAndTricks = ({ onLinkClick, faqData, type }) => {
    return (
        <OuterBox>
            <GlobalStyles />
            <Grid marginLeft={16} marginRight={16}>
                <Box style={{ backgroundColor: 'white', width: '25%', marginTop: '8px' }}>
                    <Text
                        fontSize={40}
                        marginTop={0}
                        marginBottom={0}
                        fontFamily='Bebas Neue, sans-serif'
                        fontWeight={400}
                        color='#000'
                        // gutterBottom
                        style={{ marginLeft: 2 }}
                    >
                        Tips &
                    </Text>
                </Box>
                <Text
                    fontFamily='Bebas Neue, sans-serif'
                    fontSize={40}
                    fontWeight={400}
                    marginTop={0}
                    marginBottom={0}
                    color='white'
                    // gutterBottom
                    style={{ marginLeft: 2 }}
                >
                    Trik
                </Text>
                <StyledLinkAnchored component="button" to={`/faq/livestream/all`}>CARI TAU SELENGKAPNYA</StyledLinkAnchored>
                <RootBox sx={{ paddingLeft: 0 }}>
                    {faqData?.map((data, index) => (
                        <CardBox key={index}>
                            <WhitePaper elevation={3}>
                                <Text
                                    fontFamily='Bebas Neue, sans-serif'
                                    fontSize={20}
                                    fontWeight={400}
                                    display="-webkit-box"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="normal"
                                    sx={{
                                        "-webkit-box-orient": "vertical",
                                        "-webkit-line-clamp": "2",
                                    }}
                                >
                                    {data.title}
                                </Text>
                                <StyledLink to={`/faq/${data.category_name}/all/${data.id}`} style={{ marginTop: '0', fontSize: 12 }}>BACA ARTIKEL</StyledLink>
                                {/* <StyledLink href={tip.link} target="_blank" style={{ marginTop: '0', fontSize: 12 }}>BACA ARTIKEL</StyledLink>  */}
                            </WhitePaper>
                        </CardBox>
                    ))}
                </RootBox>
            </Grid>
        </OuterBox>
    );
};

export default TipsAndTricks;
