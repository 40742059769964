import React from 'react';
import { Box, Text,  Center } from '@chakra-ui/react';
import logo from '../../assets/makeover-logo.png';

const ErrorPage = ({ error }) => {

    const renderItems = () => {
        return (
            <>
                <Center>
                    <Box style={{ width: '600px' }}>
                        <Box display="flex" style={{ maxWidth: '800px', backgroundColor: 'black' }} paddingLeft='-16px'>
                            <img src={logo} alt="Make Over Logo" style={{ maxWidth: '70px', marginLeft: '8px' }} mb={2} />
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight={600}>
                            <Text fontSize={180} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center" marginBottom={0}>
                                {error ?? 404}
                            </Text>
                            <Text fontSize={24} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center">
                                {error === 500 ? 'INTERNAL SERVER ERROR' : error === 400 ? 'BAD REQUEST' : 'PAGE NOT FOUND'}
                            </Text>
                            <Text color={'#909090'} marginLeft={5} marginRight={5} fontSize={16} fontWeight={400} letterSpacing={2} fontFamily='Proxima Nova' textAlign="center">
                                {error === 500 ? 'Halaman tidak dapat diakses. Silakan coba beberapa saat lagi.' : error === 400 ? 'Request yang kamu lakukan tidak dapat diterima oleh server' : 'Maaf, halaman tidak dapat ditemukan'}
                            </Text>
                        </Box>
                    </Box>
                </Center>
            </>
        )
    }
    return (
        <>
            <div>
                <Box>
                    {renderItems()}
                </Box>
            </div>
        </>
    );
};

export default ErrorPage;