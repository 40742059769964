import { useState, useEffect } from 'react';

const useGetPerformance = (userId, type) => {
    const [earningsData, setEarningsData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataEarnings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/affiliate/${process.env.REACT_APP_BRAND_NAME}/v1/performance/${userId}?type=${type}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                setEarningsData(result);
                setError(null);
            } catch (err) {
                const statusCode = err.message.split('status: ')[1];
                if (err.message.includes('HTTP error!')) {
                    console.log(`Error Status Code: ${statusCode}`);
                } else {
                    console.log(err.message);
                }
                setError(statusCode);
            }
        };

        if (userId && type) {
            fetchDataEarnings();
        }
    }, [userId, type]);

    return { earningsData, error };
};

export default useGetPerformance;
