import React from 'react';
import { Text, Box, Grid, Progress, Tabs, TabList, TabPanels, Tab, TabPanel, Link as ChakraLink, chakra } from '@chakra-ui/react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClickIcon from '@mui/icons-material/AdsClick';
import CheckoutIcon from '@mui/icons-material/ShoppingCart';
import CommentIcon from '@mui/icons-material/Comment';
import '@dannymichel/proxima-nova';
import { Link as RouterLink } from 'react-router-dom'

import LikesIcon from '@mui/icons-material/ThumbUp'; 
import ShareIcon from '@mui/icons-material/Share'; 
import FollowersIcon from '@mui/icons-material/People'; 
import CtrIcon from '@mui/icons-material/SwipeUp'; 
import WatchedIcon from '@mui/icons-material/Devices'; 
import CoRateIcon from '@mui/icons-material/Equalizer'; 
import DurationIcon from '@mui/icons-material/Timer'; 
import ViewedIcon from '@mui/icons-material/QueryStats'; 
import PercentageIcon from '@mui/icons-material/TaskAlt'; 
import DefaultIcon from '@mui/icons-material/HelpOutline';

const StyledLink = chakra(({ to, ...props }) => (
    <ChakraLink as={RouterLink} to={to} onClick={() => window.scrollTo(0, 0)} {...props} />
), {
    baseStyle: {
        color: '#fff',
        textDecoration: 'none',
        position: 'relative',
        fontFamily: 'Proxima Nova',
        display: 'inline-block',
        fontSize: '12px',
        letterSpacing: '1.2px',
        fontWeight: '600',
        _after: {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(to right, #FF42A1 0%, #FF42A1 100%)',
            bottom: '-2px',
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease'
        },
        _hover: {
            _after: {
                transform: 'scaleX(1.05)',
            }
        }
    }
});

const ContentAchievement = ({ onTypeChange, earningsData, type }) => {
    const handleTabChange = (index) => {
        const newType = index === 0 ? 'livestream' : 'video';
        onTypeChange(newType);
    };

    return (
        <Box>
            <Box mx="0px" mb="10px">
                <Text ml={16} mr={16} mt={10} fontSize="40px" fontWeight="400" letterSpacing="2px" fontFamily="Bebas Neue, sans-serif" color="#000">
                    PENCAPAIAN KONTEN KAMU
                </Text>
                <Tabs
                    variant="unstyled"
                    index={type === 'livestream' ? 0 : 1}
                    onChange={handleTabChange}
                    px="16px"
                    pt="24px"
                >
                    <TabList display="flex" alignItems="center" justifyContent="space-between">
                        <Tab
                            _focus={{ boxShadow: 'none' }}
                            sx={{
                                fontFamily: "Proxima Nova",
                                fontSize: "16px",
                                borderStyle: 'none',
                                width: '49.312%',
                                backgroundColor: 'white',
                                color: type === 'livestream' ? '#000' : '#000',
                                borderBottom: type === 'livestream' ? '5px solid #FF42A1' : 'none',
                                fontWeight: type === 'livestream' ? '700' : 'normal',
                                padding: '4px'
                            }}
                        >
                            LIVESTREAM
                        </Tab>
                        <Text mt="10px">/</Text>
                        <Tab
                            _focus={{ boxShadow: 'none' }}
                            sx={{
                                fontFamily: "Proxima Nova",
                                fontSize: "16px",
                                borderStyle: 'none',
                                width: '49.312%',
                                backgroundColor: 'white',
                                color: type === 'video' ? '#000' : '#000',
                                borderBottom: type === 'video' ? '5px solid #FF42A1' : 'none',
                                fontWeight: type === 'video' ? '700' : 'normal',
                                padding: '4px'
                            }}
                        >
                            VIDEO
                        </Tab>
                    </TabList>
                    <TabPanels pt="2px" pb="0px">
                        <TabPanel>
                            <Text fontSize={14} fontWeight={400} letterSpacing={1} fontFamily='Proxima Nova' style={{ marginBottom: 0, marginTop: 30, color: '#000' }}>JUMLAH LIVESTREAM KAMU</Text>
                            <Text fontFamily='Proxima Nova' fontSize={20} fontWeight={700} letterSpacing={1} style={{ marginBottom: 10,  color: '#000', marginTop: 5 }}>{earningsData?.total_content ?? 0} Konten</Text>
                            <ContentList type="livestream" earningsData={earningsData} />
                        </TabPanel>
                        <TabPanel>
                            <Text fontSize={14} fontWeight={400} letterSpacing={1} fontFamily='Proxima Nova' style={{ marginBottom: 0, marginTop: 30, color: '#000' }}>JUMLAH VIDEO KAMU</Text>
                            <Text fontFamily='Proxima Nova' fontSize={20} fontWeight={700} letterSpacing={1} style={{ marginBottom: 10, color: '#000', marginTop: 5 }}>{earningsData?.total_content ?? 0} Konten</Text>
                            <ContentList type="video" earningsData={earningsData} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    );
};

const ContentList = ({ type, earningsData }) => {

    const iconMap = {
        views: <VisibilityIcon style={{ color: 'white' }} />,//Produk Dilihat Livestream //Produk Dilihat Video 
        clicks: <ClickIcon style={{ color: 'white' }} />, //Klik Produk LiveStream //Klik Produk Video
        product: <CheckoutIcon style={{ color: 'white' }} />, //Produk
        comments: <CommentIcon style={{ color: 'white' }} />,//Komentar LiveStream //Komentar Video
        likes: <LikesIcon style={{ color: 'white' }} />, //Likes LiveStream //Likes Video
        share: <ShareIcon style={{ color: 'white' }} />, //Dibagikan
        followers: <FollowersIcon style={{ color: 'white' }} />, //Pengikut Baru LiveStream //Pengikut Baru Video
        ctr: <CtrIcon style={{ color: 'white' }} />, //CTR Video //CTR LiveStream
        viewers: <WatchedIcon style={{ color: 'white' }} />, //Penonton
        rate: <CoRateIcon style={{ color: 'white' }} />, //CO Rate Video //CO Rate Livestrem
        duration: <DurationIcon style={{ color: 'white' }} />,//Durasi
        livestreamview: <ViewedIcon style={{ color: 'white' }} />, //livestream dilihat
        percentage: <PercentageIcon style={{ color: 'white' }} /> //Persentase Video Ditonton Selesai
    };

    const typeMap = {
        views: "Produk Dilihat",//Produk Dilihat Livestream //Produk Dilihat Video 
        clicks: "Klik Produk", //Klik Produk LiveStream //Klik Produk Video
        product: "Produk", //Produk
        comments: "Comments",//Komentar LiveStream //Komentar Video
        likes: "Likes", //Likes LiveStream //Likes Video
        share: "Share", //Dibagikan
        followers: "Pengikut Baru", //Pengikut Baru LiveStream //Pengikut Baru Video
        ctr: "Klik Tayang", //CTR Video //CTR LiveStream
        viewers: "Penonton", //Penonton
        rate: "CO Rate", //CO Rate Video //CO Rate Livestrem
        duration: "Durasi",//Durasi
        livestreamview: "Livestream Dilihat", //livestream dilihat
        percentage: "Persentase" //Persentase Video Ditonton Selesai
    };

    const getIcon = (type) => {
        return iconMap[type] || <DefaultIcon style={{ color: 'white' }} />;
    };

    const getType = (type) => {
        return typeMap[type] || "Tidak Ditemukan";
    };

    const createDataFromResponse = (earningsData) => {
        if (!earningsData?.performance) return [];
        return earningsData.performance.map(item => ({
            code: item.type ?? '',
            type: getType(item.type),
            count: item.number ?? 0,
            target: item.max_number ?? 0,
            icon: getIcon(item.type),
            description: `Raih ${item.max_number} ${item.type} untuk dapetin ${earningsData?.maximum_earnings} !`,
            maximum_earnings: earningsData?.maximum_earnings ?? 0
        }));
    };

   
    const newData = createDataFromResponse(earningsData)

    const contentData = newData ?? []
    const allAchieved = newData.every(item => item.count >= item.target);
    return (
        <Box>
            <Grid spacing={2} style={{ overflowX: 'hidden', marginTop: 10 }}>
                {contentData.map((item, index) => {
                    const percentage = item.target < item.count ? 100 : (item.count / item.target) * 100;
                    const isTargetAchieved = item.count >= item.target;
                    const color = isTargetAchieved ? '#FF42A1' : '#FFCA28';
                    return (
                        <Grid  xs={12} key={index}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{ paddingLeft: '16px', position: 'relative', borderLeft: `2px solid ${color}` }}
                            >
                                <Box flexGrow={1} style={{ marginRight: '16px' }}>
                                    <Box display="flex" alignItems="center">
                                        <Box
                                            style={{
                                                width: 40,
                                                height: 40,
                                                borderRadius: '50%',
                                                backgroundColor: color,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 16,
                                            }}
                                        >
                                            {item.icon}
                                        </Box>
                                        <Box flexGrow={1} marginTop='10px'>
                                            <Text marginBottom={0} fontSize={14} fontWeight={400} letterSpacing={-0.25} fontFamily='Proxima Nova' style={{ color: '#100d1b' }}>{item.type}</Text>
                                            <Box display='flex' justifyContent='space-between'>
                                                <Text marginBottom={0} marginTop={0} fontSize={14} fontFamily='Proxima Nova' fontWeight={700} letterSpacing={-0.25} style={{ color: '#333333' }}>{item.count.toLocaleString('id-ID')}</Text>
                                                <Text marginBottom={0} marginTop={0} fontSize={14} fontWeight={400} letterSpacing={-0.25} fontFamily='Proxima Nova' style={{ color: '#909090' }}>{`dari ${item?.target.toLocaleString('id-ID')} ${item.type}`}</Text>
                                            </Box>
                                            <Progress
                                                value={percentage}
                                                height="10px"
                                                borderRadius="5px"
                                                marginBottom={10}
                                                mt="5px"
                                                bg="#f0f0f0"
                                                sx={{
                                                    '& > div': {
                                                        backgroundColor: color,
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {!isTargetAchieved && (
                                <Box
                                    style={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        padding: '8px 16px',
                                        // marginTop: 16,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        position: 'relative',
                                    }}
                                >
                                    <Box style={{ display: 'flex', alignItems: 'center', marginTop:0 }}>

                                        <Text
                                            fontFamily='Proxima Nova'
                                            style={{
                                                fontSize: '14px',
                                                width: '100%',
                                                lineHeight: '16px'
                                            }}
                                            fontWeight={400}
                                            marginBottom={0}
                                            marginTop={0}
                                        >
                                            Raih <span style={{ color: '#FF42A1', fontWeight: '700' }}>{item.target.toLocaleString('id-ID')} {item.type}</span> untuk dapetin Rp<span>{item?.maximum_earnings.toLocaleString('id-ID') ?? 0}</span>!
                                        </Text>
                                    </Box>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <StyledLink href={item.link} target="_blank">PELAJARI</StyledLink> */}
                                        <StyledLink to={`/faq/${type}/${item.code}`}>PELAJARI</StyledLink>
                                    </Box>
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            transform: 'translateX(-50%)',
                                            width: 0,
                                            height: 0,
                                            borderLeft: '15px solid transparent',
                                            borderRight: '15px solid transparent',
                                            borderBottom: '15px solid #000',
                                        }}
                                    />
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            top: '-20px',
                                            left: '0',
                                            width: '2px',
                                            height: '20px',
                                            backgroundColor: color,
                                        }}
                                    />
                                </Box>

                            )}
                        </Grid>
                    );
                })}
            </Grid>
            {allAchieved && (
                <Box
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '8px 16px',
                        marginTop: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <Text marginBottom={0} fontFamily='Proxima Nova' style={{ fontSize: '14px' }} fontWeight={400}>
                        Selamat konten {type} kamu<span style={{ color: '#FF42A1', fontWeight: 'bold' }}> sudah optimal</span> untuk dapetin komisi sampai Rp{earningsData?.maximum_earnings.toLocaleString('id-ID')}!
                    </Text>
                </Box>
            )}
        </Box>
    );
};

export default ContentAchievement;
