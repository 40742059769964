import React from 'react';
import { Text, Grid, GridItem, Box, chakra, Link as ChakraLink, extendTheme, ChakraProvider } from '@chakra-ui/react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import arrowDown from "../../assets/Icon.png";
import makeOverLogo from '../../assets/makeover-logo.png';
import '@dannymichel/proxima-nova';

const theme = extendTheme({
    colors: {
        customPink: {
            500: '#C31575',
        },
        customPinkLight: {
            500: '#ff50a0',
        },
    },
});

const OuterBox = (props) => (
    <Box
        w="100%"
        bg="#000"
        p={{ base: '0', md: '16px 0px' }}
        overflowX="hidden"
        fontFamily="Bebas Neue"
        {...props}
    />
);

const CardBox = (props) => (
    <Box
        bg="#ffffff"
        borderRadius="8px"
        pt="8px"
        pb="8px"
        textAlign="center"
        fontFamily="'Bebas Neue', sans-serif"
        minWidth={{ base: "150px", sm: "185px" }}
        {...props}
    />
);

const StyledLink = chakra(ChakraLink, {
    baseStyle: {
        color: 'white',
        textDecoration: 'none',
        position: 'relative',
        fontFamily: 'Proxima Nova, sans-serif',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '1.2px',
        _after: {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '1px',
            background: 'linear-gradient(to right, #FFF 0%, #FFF 100%)',
            bottom: 0,
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease',
        },
        _hover: {
            _after: {
                transform: 'scaleX(1.05)',
            },
        },
    },
});

const CommissionSummary = ({ onLinkClick, userData }) => {
    const items = [
        { title: 'LIVESTREAM', amount: userData?.earnings.livestream ?? 0, color: '#1C1C1C' },
        { title: 'VIDEO', amount: userData?.earnings.video ?? 0, color: '#7E80FF' },
    ];

    const censorText = (text) => {
        if (!text) return '';
        const length = text.length;

        if (length < 3) {
            return '*'.repeat(length);
        }

        else if (length === 3) {
            return text[0] + '*' + text[2];
        }

        else if (length === 4) {
            return text[0] + '**' + text[3];
        }
        else {
            const start = text.slice(0, 2);
            const end = text.slice(-2);
            const middle = text.slice(2, -2).replace(/[^\s]/g, '*');
            return start + middle + end;
        }
    };

    const renderItems = () => (
        <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
            {items.map((item, index) => {
                const percentage = (item.amount / (userData?.total_earning || 1)) * 100;
                return (
                    <Box key={index} flexBasis={{ base: "45%", sm: "30%", md: "20%" }} maxWidth="185px">
                        <CardBox>
                            <Box
                                width="60px"
                                height="60px"
                                borderRadius="full"
                                display="flex"
                                position="relative"
                                justifyContent="center"
                                alignItems="center"
                                mb={1}
                                mx="auto"
                                backgroundColor="white"
                            >
                                <CircularProgressbar
                                    value={percentage}
                                    strokeWidth={20}
                                    styles={buildStyles({
                                        backgroundColor: "white",
                                        textColor: "#000",
                                        pathColor: item.color,
                                        trailColor: "#f0f0f0",
                                    })}
                                />
                            </Box>
                            <Text mt={2} mb={0} fontFamily='Proxima Nova' fontSize={12} letterSpacing={1} fontWeight={600} color='#000000'>
                                {item.title}
                            </Text>
                            <Text mt={1} mb={0} fontFamily='Proxima Nova' fontSize={12} letterSpacing={1} fontWeight={600} color='#000'>
                                {`Rp${item.amount.toLocaleString('id-ID')}`}
                            </Text>
                        </CardBox>
                    </Box>
                );
            })}
        </Box>
    );

    return (
        <ChakraProvider theme={theme}>
            <OuterBox>
                <Grid style={{ backgroundColor: 'black', color: '#fff', margin: '0 auto', maxWidth: '800px' }}>
                    <GridItem paddingLeft="16px" paddingRight="16px">
                        <Box display="flex" alignItems="center">
                            <img src={makeOverLogo} alt="Make Over Logo" style={{ width: '72px', marginRight: '8px' }} />
                        </Box>
                        <Text fontFamily='Bebas Neue, sans-serif' fontSize={36} letterSpacing={2} marginBottom={0} fontWeight={400}>Halo, </Text>
                        <Text maxWidth={300} fontFamily='Bebas Neue, sans-serif' fontSize={36} letterSpacing={2} marginBottom={0} fontWeight={400}>{censorText(userData?.affiliate_name ?? 'Make Over')}</Text>
                        <Box mt={2} display="flex" alignItems="center" >
                            <Text fontFamily='Proxima Nova' fontSize={14} letterSpacing={2} marginTop={5} marginBottom={0} fontWeight={400} textTransform="uppercase">LAST UPDATE {moment(userData?.last_updated_timestamp).format('LLL')}</Text>
                        </Box>
                        <Box>
                            <Text fontFamily='Proxima Nova' fontSize={14} letterSpacing={1} marginTop={2} marginBottom={0} fontWeight={400}>TOTAL KOMISI</Text>
                            <Box mt={1} display="flex" alignItems="baseline">
                                <Text fontFamily='Bebas Neue, sans-serif' fontSize={24} letterSpacing={1} marginTop={0} marginBottom={0} fontWeight={400} style={{ marginRight: '4px' }}>Rp</Text>
                                <Text fontFamily='Bebas Neue, sans-serif' fontSize={48} letterSpacing={1} marginTop={0} marginBottom={0} fontWeight={400}>{userData?.total_earning?.toLocaleString('id-ID') ?? 0}</Text>
                            </Box>
                        </Box>
                        <Box
                            mt={2}
                            p={2}
                            position="relative"
                            bgGradient="linear(to-br, customPink.500, customPinkLight.500)"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '10px',
                                    width: 0,
                                    height: 0,
                                    borderLeft: '15px solid transparent',
                                    borderRight: '15px solid transparent',
                                    borderBottom: '15px solid #C31575',
                                }}
                            />
                            <Box display="flex" flexDirection="column" justifyContent="space-between" height={103} bg="transparent" pl={4}>
                                <Text fontFamily="Proxima Nova" mt={4} mb={0} fontSize={12} letterSpacing={1.2} fontWeight={400}>
                                    MAKSIMALKAN KOMISI KAMU SAMPAI
                                </Text>
                                <Box display="flex" alignItems="baseline">
                                    <Text fontFamily="Proxima Nova" mt={0} mb={1} fontSize={16} letterSpacing={1.2} fontWeight={700}>
                                        Rp
                                    </Text>
                                    <Text fontFamily="Proxima Nova" mt={0} mb={1} fontSize={24} letterSpacing={1.2} fontWeight={700}>
                                        {userData?.affliate_tier?.maximum_earnings?.toLocaleString('id-ID') ?? 0}
                                    </Text>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <StyledLink as="button" onClick={onLinkClick} mb={4}>
                                        CARI TAU CARANYA
                                    </StyledLink>
                                    <Box onClick={onLinkClick}>
                                        <img src={arrowDown} alt="arrowDown" style={{ width: '12px', marginLeft: '10.48px', marginBottom: '10px' }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            justifyContent='center'
                            marginTop='8px'
                            marginBottom='24px'
                            display='flex'
                            gap='3%'>
                            {renderItems()}
                        </Box>
                    </GridItem>
                </Grid>
            </OuterBox>
        </ChakraProvider>
    );
};

export default CommissionSummary;
