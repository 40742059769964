import React, { useState } from 'react';
import logo from '../assets/makeover-logo.png';
import backlogo from '../assets/back-logo.png';
import ContentFaq from '../components/ListPage/ContentFaq';
import { useNavigate } from 'react-router-dom';
import { Center, Text, Box } from '@chakra-ui/react';

const ListPage = ({ userId }) => {
    const [error, setError] = useState(false)
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${userId}`);
    };
    const handleError = () => {
        setError(true);
    };
    
    const renderItems = () => {
        return (
            <Center>
                <Box style={{ width: '600px' }}>
                    <Box display="flex" style={{ maxWidth: '600px', backgroundColor: 'black', marginBottom: '24px', height: '70px' }}>
                        <img src={logo} alt="Make Over Logo" style={{ maxWidth: '70px', marginLeft: '16px' }} />
                    </Box>
                    <Box my={3}>
                        <img
                            src={backlogo}
                            alt="Back Logo"
                            onClick={handleClick}
                            style={{ maxWidth: '70px', marginLeft: '16px', cursor: 'pointer' }}
                        />
                    </Box>
                    {error ? <></> : <>
                        <Text
                            variant="h4"
                            component="h1"
                            marginLeft={16}
                            fontSize={40}
                            fontFamily='Bebas Neue, sans-serif'
                            fontWeight={400}
                            marginBottom={0}
                            color='#000'>
                            TIPS & TRIK
                        </Text>
                    </>}
                </Box>
            </Center>
        );
    };

    return (
        <>
            <div>
                <Box>
                    {renderItems()}
                    <ContentFaq onClickError={handleError} userId={userId} />
                </Box>          
            </div>
        </>
    );
};

export default ListPage;
