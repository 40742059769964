import React, { useEffect, useState } from 'react';
import { Link as ChakraLink, TabPanel, Tab, Tabs, Text, Center, Box, Grid, chakra, TabList, TabPanels } from '@chakra-ui/react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import '@dannymichel/proxima-nova';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useGetListFaq from '../../hooks/useGetListFaq';
import useLogActivity from '../../hooks/usePostLogActivity';
import placeholderImage from '../../assets/placeholder.png';

const OuterBox = (props) => (
    <Box
        data-testid='unit-test'
        w="100%"
        bg="#fff"
        p={{ base: '0', md: '16px 0' }}
        overflowX="hidden"
        fontFamily="Bebas Neue"
        {...props}
    />
);

const StyledLink = chakra(({ to, ...props }) => (
    <ChakraLink as={RouterLink} to={to} onClick={() => window.scrollTo(0, 0)} {...props} />
), {
    baseStyle: {
        color: 'black',
        textDecoration: 'none',
        position: 'relative',
        fontFamily: 'Proxima Nova',
        display: 'inline-block',
        fontSize: '12px',
        letterSpacing: '1.2px',
        fontWeight: '600',
        _after: {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(to right, #FF42A1 0%, #FF42A1 100%)',
            bottom: '-2px',
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease'
        },
        _hover: {
            _after: {
                transform: 'scaleX(1.05)',
            }
        }
    }
});

const ContentFaq = ({ onClickError, userId }) => {
    const navigate = useNavigate();
    const { type, sub_type } = useParams();

    const [selectedTab, setSelectedTab] = useState(type || 'livestream');
    const faqLimit = 100;
    const { faqData, error } = useGetListFaq(selectedTab, faqLimit, sub_type);
    useLogActivity(userId, sub_type === 'all' ? 'open_faq_list' : 'open_faq_pelajari', sub_type === 'all' ? type : sub_type);

    useEffect(() => {
        if (error) {
            onClickError();
        }
    }, [error, onClickError]);

    if (error) {
        return (
            <Center>
                <Box style={{ width: '600px' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight={600}>
                        <Text fontSize={180} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center" marginBottom={0}>
                            {404}
                        </Text>
                        <Text fontSize={24} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center">
                            {'TIPS AND TRICK'}
                        </Text>
                        <Text color={'#909090'} marginLeft={5} marginRight={5} fontSize={16} fontWeight={400} letterSpacing={2} fontFamily='Proxima Nova' textAlign="center">
                            {'Maaf, Artikel tidak dapat ditemukan'}
                        </Text>
                    </Box>
                </Box>
            </Center>
        );
    }
    const handleClickLivestream = () => {
        navigate("/faq/livestream/all");
        setSelectedTab('livestream');
    };

    const handleClickVideo = () => {
        navigate("/faq/video/all");
        setSelectedTab('video');
    };

    return (
        <Center>
            <Box style={{ width: '600px' }}>
                <OuterBox>
                    {sub_type === 'all'
                        ?
                        <>
                            <Tabs variant="unstyled" paddingLeft={'16px'} paddingRight={'16px'} paddingTop={'24px'}>
                                <TabList>
                                    <Tab onClick={handleClickLivestream}
                                        style={{
                                            fontFamily: "Proxima Nova",
                                            fontSize: "16px",
                                            borderStyle: 'none',
                                            width: '49.312%',
                                            backgroundColor: 'white',
                                            color: selectedTab === 'livestream' ? '#000' : '#000',
                                            borderBottom: selectedTab === 'livestream' ? '5px solid #FF42A1' : 'none',
                                            fontWeight: selectedTab === 'livestream' ? '700' : 'normal',
                                            padding: '4px'
                                        }}>
                                        LIVESTREAM
                                    </Tab>
                                    <Text style={{ marginTop: '10px' }}>/</Text>
                                    <Tab onClick={handleClickVideo}
                                        style={{
                                            fontFamily: "Proxima Nova",
                                            fontSize: "16px",
                                            borderStyle: 'none',
                                            width: '49.312%',
                                            backgroundColor: 'white',
                                            color: selectedTab === 'video' ? '#000' : '#000',
                                            borderBottom: selectedTab === 'video' ? '5px solid #FF42A1' : 'none',
                                            fontWeight: selectedTab === 'video' ? '700' : 'normal',
                                            padding: '4px'
                                        }}>
                                        VIDEO
                                    </Tab>
                                </TabList>
                                <TabPanels paddingTop={'24px'} paddingBottom={'32px'}>
                                    <TabPanel>
                                        <ContentList category="livestream" faqData={faqData} sub_type={sub_type} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ContentList category="video" faqData={faqData} sub_type={sub_type} />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </>
                        :
                        <>
                            <div style={{ marginLeft: '16px', marginRight: '16px' }}>
                                <ContentList category="video" faqData={faqData} sub_type={sub_type} />
                            </div>
                        </>
                    }
                </OuterBox>
            </Box>
        </Center>
    );
};

const ContentList = ({ category, faqData, sub_type }) => {
    return (
        <Box
            height="456px"
            overflowY="scroll"
            sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
            }}
        >
            <Grid spacing={0} style={{ overflowX: 'hidden' }}>
                {faqData?.map((item, index) => (
                    <Grid xs={12} key={index}>
                        <Box
                            display="flex"
                            height="114px"
                            alignItems="center"
                        >
                            {item.image_cover ? (
                                <div
                                    style={{
                                        width: '88px',
                                        height: '88px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <img
                                        src={item.image_cover}
                                        alt={item.title}
                                        style={{
                                            width: '88px',
                                            height: '88px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            ) :
                                (
                                    <div style=
                                        {{
                                            width: '88px',
                                            height: '88px',
                                            overflow: 'hidden',
                                        }}>
                                        <img
                                            src={placeholderImage}
                                            alt={item.title}
                                            style={{
                                                width: '88px',
                                                height: '88px',
                                            }}
                                        />
                                    </div>
                                )}
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                width='70%'
                                style={{ paddingLeft: '16px', height: '88px' }}
                            >
                                <Box>
                                    <Text
                                        fontSize="12px"
                                        fontFamily="Proxima Nova"
                                        fontWeight="400"
                                        lineHeight="14.62px"
                                        style={{ color: '#909090', marginBottom: '4px', marginTop: '0px' }}
                                    >
                                        {moment(item.updated_time).format('ll')}
                                    </Text>
                                    <Text
                                        fontSize="20px"
                                        fontWeight="400"
                                        fontFamily="Bebas Neue"
                                        lineHeight="24px"
                                        style={{ color: '#000', marginTop: '0px', marginBottom: '0px' }}
                                    >
                                        {item.title}
                                    </Text>
                                </Box>
                                <Box>
                                    <StyledLink to={`/faq/${item.category_name}/${sub_type}/${item.id}`}>
                                        BACA ARTIKEL
                                    </StyledLink>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ContentFaq;
