import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ListPage from './pages/ListPage';
import DetailPage from './pages/DetailPage';
import ErrorPage from './components/ErrorPage/ErrorPage';

export const AppRoutes = ({ setUserId, userId }) => (
  <Routes>
    <Route path="/" element={<ErrorPage />} />
    <Route path="/faq/:type" element={<ErrorPage />} />
    <Route path="/:userId" element={<MainPage setUserId={setUserId} />} />
    <Route path="/faq/:type/:sub_type" element={<ListPage userId={userId} />} />
    <Route path="/faq/:type/:sub_type/:topic" element={<DetailPage userId={userId} />} />
  </Routes>
);

const App = () => {
  const [userId, setUserId] = useState(null);

  return (
    <Router>
      <AppRoutes setUserId={setUserId} userId={userId} />
    </Router>
  );
};

export default App;
