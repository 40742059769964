import React from 'react';
import { Link as ChakraLink, Text, Center, Box, Grid, chakra, Tabs, TabPanel, TabPanels } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import '@dannymichel/proxima-nova';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useGetListFaq from '../../hooks/useGetListFaq';
import useGetDetailFaq from '../../hooks/useGetDetailFaq';
import useLogActivity from '../../hooks/usePostLogActivity';
import placeholderImage from '../../assets/placeholder.png';

const StyledLink = chakra(({ to, ...props }) => (
    <ChakraLink as={RouterLink} to={to} onClick={() => window.scrollTo(0, 0)} {...props} />
), {
    baseStyle: {
        color: 'black',
        textDecoration: 'none',
        position: 'relative',
        fontFamily: 'Proxima Nova',
        display: 'inline-block',
        fontSize: '12px',
        letterSpacing: '1.2px',
        fontWeight: '600',
        _after: {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(to right, #FF42A1 0%, #FF42A1 100%)',
            bottom: '-2px',
            left: 0,
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease'
        },
        _hover: {
            _after: {
                transform: 'scaleX(1.05)',
            }
        }
    }
});

const DetailContentFaq = ({ userId }) => {
    const { type, topic, sub_type } = useParams();
    const { detailFaq, error: detailError } = useGetDetailFaq(type, sub_type, topic);
    const { faqData, error: listError } = useGetListFaq(type, 5, sub_type);
    useLogActivity(userId, 'open_faq_detail', topic);

    const error = detailError || listError;
    if (error) {
        return (
            <Center>
                <Box style={{ width: '600px' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight={600}>
                        <Text fontSize={180} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center" marginBottom={0}>
                            {404}
                        </Text>
                        <Text fontSize={24} fontWeight={400} letterSpacing={2} fontFamily='Bebas Neue, sans-serif' textAlign="center">
                            {'TIPS AND TRICK'}
                        </Text>
                        <Text color={'#909090'} marginLeft={5} marginRight={5} fontSize={16} fontWeight={400} letterSpacing={2} fontFamily='Proxima Nova' textAlign="center">
                            {'Maaf, Artikel tidak dapat ditemukan'}
                        </Text>
                    </Box>
                </Box>
            </Center>
        );
    }

    return (
        <Center>
            <Box style={{ width: '600px' }}>
                <Tabs>
                    <TabPanels paddingTop={'24px'} paddingBottom={'32px'}>
                        <TabPanel>
                            <ContentList detailFaq={detailFaq} faqData={faqData} sub_type={sub_type} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Center>
    );
};

const ContentList = ({ detailFaq, faqData, sub_type }) => {
    return (
        <Box style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid spacing={0} style={{ overflowX: 'hidden' }}>
                <Text
                    fontSize='24px'
                    fontFamily='Bebas Neue, sans-serif'
                    fontWeight={400}
                    lineHeight='24px'
                    color='#000'
                    marginTop='0px'
                    marginBottom='8px'>
                    {detailFaq?.title}
                </Text>
                <Box display='flex'>
                    <Text
                        fontSize='12px'
                        fontFamily='Proxima Nova'
                        fontWeight={400}
                        lineHeight='14.62px'
                        letterSpacing='1px'
                        color='#000'
                        marginRight='8px'
                        marginTop='0px'
                        style={{ textTransform: 'uppercase' }}
                        marginBottom='24px'>
                        {detailFaq?.category_name}
                    </Text>
                    <Text
                        fontSize='12px'
                        fontFamily='Proxima Nova'
                        fontWeight={400}
                        lineHeight='14.62px'
                        letterSpacing='1px'
                        color='#979797'
                        marginRight='8px'
                        marginTop='0px'
                        marginBottom='24px'>
                        |
                    </Text>
                    <Text
                        fontSize='12px'
                        fontFamily='Proxima Nova'
                        fontWeight={400}
                        lineHeight='14.62px'
                        letterSpacing='1px'
                        color='#000'
                        marginTop='0px'
                        marginBottom='24px'>
                        Diperbarui&nbsp;{moment(detailFaq?.updated_time).format('ll')}
                    </Text>
                </Box>
                {detailFaq.image_cover &&
                    <img
                        src={detailFaq?.image_cover}
                        alt={detailFaq?.title}
                        style={{
                            display: 'block',
                            margin: '0 auto',
                            width: '100%',
                            height: 'auto',
                        }}
                    />
                }
                <Text
                    fontSize='16px'
                    fontFamily='Proxima Nova'
                    fontWeight={400}
                    lineHeight='20px'
                    color='#000'
                    marginTop='24px'
                    marginBottom='24px'
                    maxWidth='600px'
                    marginRight='12px'
                    dangerouslySetInnerHTML={{ __html: detailFaq?.content }}
                />
            </Grid>
            <Grid spacing={0} style={{ overflowX: 'hidden' }}>
                <Text
                    fontSize='24px'
                    fontFamily='Bebas Neue, sans-serif'
                    fontWeight={400}
                    lineHeight='24px'
                    color='#000'
                    marginTop='16px'
                    marginBottom='16px'>
                    RELATED ARTICLES
                </Text>
                <Box
                    height="456px"
                    overflowY="scroll"
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                    }}>
                    {faqData?.map((item, index) => {
                        return (
                            <Grid xs={12} key={index} display="flex" height="114px" alignItems="center">
                                {item.image_cover ? (
                                    <div
                                        style={{
                                            width: '88px',
                                            height: '88px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            src={item.image_cover}
                                            alt={item.title}
                                            style={{
                                                width: '88px',
                                                height: '88px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                ) :
                                    (
                                        <div style=
                                            {{
                                                width: '88px',
                                                height: '88px',
                                                overflow: 'hidden',
                                            }}>
                                            <img
                                                src={placeholderImage}
                                                alt={item.title}
                                                style={{
                                                    width: '88px',
                                                    height: '88px',
                                                }}
                                            />
                                        </div>
                                    )}
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    width='70%'
                                    style={{ paddingLeft: '16px', height: '88px' }}
                                >
                                    <Box>
                                        <Text fontSize='12px' fontFamily='Proxima Nova' fontWeight='400' lineHeight='14.62px' style={{ color: '#909090', marginBottom: '4px', marginTop: '0px' }}>{moment(item.updated_time).format('ll')}</Text>
                                        <Text fontSize='20px' fontWeight='400' fontFamily='Bebas Neue' lineHeight='24px' style={{ color: '#000', marginTop: '0px', marginBottom: '0px' }}>{item.title}</Text>
                                    </Box>
                                    <Box>
                                        <StyledLink to={`/faq/${item.category_name}/${sub_type}/${item.id}`}>
                                            BACA ARTIKEL
                                        </StyledLink>
                                    </Box>
                                </Box>
                            </Grid>
                        );
                    })}
                </Box>
            </Grid>
        </Box>
    );
};

export default DetailContentFaq;
