import { useState, useEffect } from 'react';

const useLogActivity = (user_id, activity_name, activity_detail) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const logActivity = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/affiliate/makeover/v1/activity`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        creator_id : user_id,
                        activity_name,
                        activity_detail,
                    }),
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }

                const result = await res.json();
                setResponse(result);
            } catch (err) {
                const statusCode = err.message.split('status: ')[1];
                if (err.message.includes('HTTP error!')) {
                    console.log(`Error Status Code: ${statusCode}`);
                } else {
                    console.log(err.message);
                }
                setError(statusCode);
            }
        };

        logActivity();
    }, [user_id, activity_name, activity_detail]);

    return { response, error };
};

export default useLogActivity;
