import { useState, useEffect } from 'react';

const useGetListFaq = (type, faqLimit, sub_type) => {
    const [faqData, setFaqData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataFaq = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/affiliate/${process.env.REACT_APP_BRAND_NAME}/v1/faq?type=${type}&limit=${faqLimit}&sub_type=${sub_type}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                setFaqData(result);
                setError(null);
            } catch (err) {
                const statusCode = err.message.split('status: ')[1];
                if (err.message.includes('HTTP error!')) {
                    console.log(`Error Status Code: ${statusCode}`);
                } else {
                    console.log(err.message);
                }
                setError(statusCode);
            }
        };

        if (type && faqLimit && sub_type) {
            fetchDataFaq();
        }
    }, [type, faqLimit, sub_type]);

    return { faqData, error };
};

export default useGetListFaq;
