import React, { useRef, useState, useEffect } from 'react';
import CommissionSummary from '../components/LandingPage/CommissionSummary';
import ContentAchievement from '../components/LandingPage/ContentAchievement';
import TipsAndTricks from '../components/LandingPage/TipsAndTricks';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Center, Box } from '@chakra-ui/react';
import useGetUser from '../hooks/useGetUser';
import useGetPerformance from '../hooks/useGetPerformance';
import useGetListFaq from '../hooks/useGetListFaq';
import useLogActivity from '../hooks/usePostLogActivity';

const MainPage = ({ setUserId }) => {
    const contentAchievementRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [type, setType] = useState('livestream');
    const { userId } = useParams();

    const { userData, error: userError } = useGetUser(userId);
    const { earningsData, error: earningsError } = useGetPerformance(userId, type);
    const { faqData, error: faqError } = useGetListFaq(type, process.env.REACT_APP_LIMIT, 'all');
    useLogActivity(userId, 'open_landing', null);

    useEffect(() => {
        setUserId(userId);
    }, [userId, setUserId]);

    useEffect(() => {
        if (userData && earningsData && faqData) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [userData, earningsData, faqData]);

    const scrollToContentAchievement = () => {
        if (contentAchievementRef.current) {
            contentAchievementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleTypeChange = (newType) => {
        setType(newType);
    };

    const MainContainer = () => {
        return (
            <Center>
                <Box width={600}>
                    <div>
                        <Box>
                            <CommissionSummary onLinkClick={scrollToContentAchievement} userData={userData} />
                            <div ref={contentAchievementRef}>
                                <ContentAchievement onTypeChange={handleTypeChange} earningsData={earningsData} type={type} />
                            </div>
                            <TipsAndTricks onLinkClick={scrollToContentAchievement} faqData={faqData} type={type} />
                        </Box>
                    </div>
                </Box>
            </Center>
        );
    };

    if (userError || earningsError || faqError) {
        return <ErrorPage error={userError || earningsError || faqError} />;
    }

    return (
        <>
            <MainContainer />
            {loading && (
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
};

export default MainPage;
